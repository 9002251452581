import {
  // DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import axios from "axios";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { database } from "../configs/firebase";

const Map = () => {
  // const [directionsResponse, setDirectionsResponse] = useState(null);
  const [IsLoading, setIsLoading] = useState(false);
  const [Zoom, setZoom] = useState(12);
  const [IsShowDetail, setIsShowDetail] = useState(true);
  const [Center, setCenter] = useState(null);
  const [searchParams] = useSearchParams();
  const [Data, setData] = useState({
    workers: [],
    customer: {},
  });
  const navigate = useNavigate();

  const [showInfo, setShowInfo] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
  });

  const getFromFirebase = (data = null) => {
    let listWorkers = data?.workers;
    listWorkers.forEach((element, i) => {
      const datas = ref(database, `/locations/${element.id}`);
      onValue(datas, (snap) => {
        const location = snap.val();
        if (location) {
          setData({
            customer: data?.customer,
            workers: listWorkers.map((e) =>
              e.id === element.id
                ? {
                    ...e,
                    latitude: location.lat,
                    longitude: location.lng,
                    is_firebase: true,
                  }
                : e
            ),
          });

          listWorkers = listWorkers.map((e) =>
            e.id === element.id
              ? {
                  ...e,
                  latitude: location.lat,
                  longitude: location.lng,
                  is_firebase: true,
                }
              : e
          );
        } else {
          setData({
            customer: data?.customer,
            workers: listWorkers.map((e) => (e.id === element.id ? e : e)),
          });
        }

        if (i + 1 === listWorkers.length) {
          setIsLoading(false);
        }
      });
    });
  };

  useEffect(() => {
    localStorage.clear();
    const projectId = searchParams.get("project_id");
    const token = searchParams.get("token");
    if (!projectId || !token) {
      navigate("error");
    }

    const getData = async () => {
      try {
        setIsLoading(true);
        localStorage.clear();

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/trackings?project_id=${projectId}&token=${token}`
        );

        return data.data;
      } catch (error) {
        console.log(error);
        navigate("error");
      }
    };

    if (projectId && token) {
      getData().then((res) => getFromFirebase(res));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFromFirebase({ workers: [], customer: {} });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Loading = () => (
    <div className="d-flex justify-content-center align-items-center position-absolute w-100 h-100">
      <img src={`${window.location.origin}/loading.svg`} alt="" />
    </div>
  );

  if (!isLoaded) return <Loading />;
  if (IsLoading) return <Loading />;
  if (!IsLoading) {
    return (
      <div className="">
        <div className="p-3 project-detail-wrapper">
          <div className="bg-white p-4 rounded shadow border-danger border-top border-5">
            <div className="row">
              <div className="col-6 m-auto">
                <img
                  data-tut="kanggo__image"
                  src="https://kanggo.id/wp-content/uploads/2022/02/logo-kanggo-287-x-81.webp"
                  className="img-kanggo"
                  alt="kanggo"
                />
              </div>
              <div
                className="col-6 m-auto text-end"
                data-tut="showhide__button"
              >
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => setIsShowDetail(!IsShowDetail)}
                >
                  {IsShowDetail ? "Hide" : "Show"} Detail
                </button>
              </div>
            </div>
            <div
              className="row mt-3 cursor-pointer"
              data-tut="project__detail"
              onClick={() =>
                setCenter({
                  lat: Data.customer?.latitude,
                  lng: Data.customer?.longitude,
                })
              }
            >
              <div className="col-3 m-auto">
                <img
                  src={Data?.customer.image_uri}
                  className="bulding-type-image"
                  alt={Data?.customer.image_uri}
                />
              </div>
              <div className="col-9 m-auto">
                <p className="p-0 m-0">{Data?.customer.jenis_bangunan}</p>
                <p className="p-0 m-0 work_location">
                  {Data.customer?.address || ""}
                </p>
              </div>
            </div>
          </div>
          <div className="gap m-2"></div>
          {IsShowDetail && (
            <div
              className={`overflow-auto rounded ${
                Data.workers.length > 4 && "overflow-height"
              }`}
              data-tut="worker__list"
            >
              <div className="bg-white p-4 shadow">
                {Data.workers.map((e, i) => (
                  <div
                    data-tut="worker__detail"
                    className="row mb-2 cursor-pointer"
                    key={i}
                    onClick={async () => {
                      setCenter({ lat: e.latitude, lng: e.longitude });
                      setZoom(100);
                      // handleTracking(e, Data.customer);
                      localStorage.setItem("worker_track", e.id);
                      // setShowInfo(i)
                    }}
                  >
                    <div className="col-3 m-auto">
                      <img
                        src={e.image}
                        className="rounded-circle border border-danger border-2 worker-img"
                        alt={e.image}
                      />
                    </div>
                    <div className="col-9 m-auto">
                      <div className="d-flex justify-content-between">
                        <span className="p-0 m-0 worker_id">{e.id}</span>
                        {/* <div
                          className={`${
                            e.is_firebase ? "bg-success" : "bg-danger"
                          } `}
                          style={{ width: 10, height: 10 }}
                        ></div> */}
                      </div>
                      <p className="p-0 m-0 worker_name">
                        {e.name} - {e?.skill_name || "Tukang Batu"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="">
          {!IsLoading && (
            <GoogleMap
              zoom={Zoom}
              onLoad={(map) => {
                if (Data.customer && Data.workers.length > 0) {
                  const markers = [...Data.workers, Data.customer];
                  let bounds = new window.google.maps.LatLngBounds();
                  for (var i = 0; i < markers.length; i++) {
                    bounds.extend({
                      lat: markers[i].latitude,
                      lng: markers[i].longitude,
                    });
                  }

                  map.fitBounds(bounds);
                }
              }}
              center={Center}
              mapContainerClassName="map-container"
              options={{
                disableDefaultUI: true,
                clickableIcons: true,
              }}
            >
              {/* <TrafficLayer /> */}
              {Data.customer?.latitude && Data.customer?.longitude && (
                <Marker
                  position={{
                    lat: Data.customer?.latitude,
                    lng: Data.customer?.longitude,
                  }}
                  icon={`${window.location.origin}/home.png`}
                />
              )}
              {/* {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )} */}
              {Data.workers.map((e, i) => (
                <div key={i}>
                  {e.latitude && e.longitude && (
                    <Marker
                      position={{ lat: e.latitude, lng: e.longitude }}
                      onClick={async () => {
                        setShowInfo(i);
                      }}
                      icon={`${window.location.origin}/worker.png`}
                    />
                  )}
                  {showInfo === i && (
                    <InfoWindow
                      onCloseClick={() => setShowInfo(null)}
                      position={{ lat: e.latitude, lng: e.longitude }}
                    >
                      <div className="container">
                        <div className="text-center">
                          <img
                            className="worker-img-detail"
                            src={e.image}
                            alt={e.image}
                          />
                        </div>
                        <span className="d-block text-center text-dark fw-bold pt-2">
                          {e.name}
                        </span>
                        <span className="d-block text-center text-dark">
                          {e.skill_name}
                        </span>
                      </div>
                    </InfoWindow>
                  )}
                </div>
              ))}
            </GoogleMap>
          )}
        </div>
      </div>
    );
  }
};

export default Map;
