import React from "react";

const SomethingWrong = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center position-absolute w-100 h-100 text-center">
      <img src={`${window.location.origin}/error.png`} alt="" />
      <h1 className="d-block fw-600 fw-bold my-2">Something Wrong</h1>
    </div>
  );
};

export default SomethingWrong;
